<template>
  <div class="container">
    <modelo-impressao-form />
    <b-modal id="modal-form" hide-footer title=""> </b-modal>
  </div>
</template>

<script>
import ModeloImpressaoForm from "../../components/Impressoras/ModeloImpressaoForm.vue";
export default {
  components: {
    ModeloImpressaoForm
  },
  props: {
   
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>