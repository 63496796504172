<template>
  <div>
    <div class="row mt-3 mb-3">
      <div class="col-6">
        <b-form-group label="Nome do Modelo">
          <b-input />
        </b-form-group>
      </div>
      <!-- <div class="col-6">
        <b-form-group label="Tipo do Modelo">
          <b-select
            :options="['Geral', 'Senha', 'Pedido', 'Pedido Entrega', 'Pedido Mesa', 'Conta Mesa']"
          />
        </b-form-group>
      </div> -->
    </div>

    <div class="row mt-3">
      <div class="col-12 text-left">
        <b-btn variant="info" @click="modeloPadrao">Modelo Padrão</b-btn>
      </div>
      <div class="col-5">
        <div class="prev">
          <div
            class="linha"
            v-for="n in linhas"
            :key="`lin_nu${n.count}_${Math.random()}`"
            @click="select(n)"
            :class="selected && selected.count === n.count ? 'selected' : ''"
          >
            <div class="count">{{ n.count }}</div>
            <div
              class="w-100 h-100"
              :style="
                `border-bottom:2px ${
                  n.tipo == 'L' ? 'solid' : 'dotted'
                } #000; height:100%;`
              "
              v-if="['L', 'LP'].includes(n.tipo)"
            >
              &nbsp;
            </div>
            <div
              v-if="!['L', 'LP'].includes(n.tipo)"
              class="texto"
              :class="
                (n.negrito ? 'font-weight-bold' : '') +
                  ' ' +
                  (n.tamanho == 'G'
                    ? 'tGrande'
                    : n.tamanho == 'M'
                    ? 'tMedio'
                    : '') +
                  ' ' +
                  (n.alinhamento == 'C'
                    ? 'text-center'
                    : n.alinhamento == 'D'
                    ? 'text-right'
                    : 'text-left') +
                  ' ' +
                  (['T2', 'DH'].includes(n.tipo)
                    ? 'd-flex justify-content-between'
                    : '')
              "
            >
              <span>{{ n.texto }}</span>
              <span
                v-if="['T2', 'DH'].includes(n.tipo)"
                class="text-right float-rigth"
              >
                {{ n.texto2 }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!----------------- FORM --------------->
      <div class="col-7 text-left">
        <span v-if="!selected">
          Selecione uma linha no modelo ao lado para editar
        </span>
        <div class="row" v-if="selected">
          <div class="col-12 text-center font-weight-bold">
            LINHA SELECIONADA {{ selected.count }}
          </div>

          <div class="col-6">
            <b-form-group label="Tipo de Campo">
              <b-select :options="tipos" v-model="selected.tipo" />
            </b-form-group>
          </div>
          <div class="col-12" v-if="!['L', 'LP'].includes(selected.tipo)">
            <hr />
            <div class="row">
              <div class="col-2 pl-0 pr-1  text-center">
                <label>Negrito</label>
                <b-check
                  switch
                  size="lg"
                  v-model="selected.negrito"
                  :value="true"
                  :unchecked-value="false"
                ></b-check>
              </div>
              <div class="col-4 pl-0 pr-1 text-center ">
                <label>Alinhamento</label>
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="selected.alinhamento"
                  name="radios-alinhamento"
                >
                  <b-radio value="E"><b-icon-align-start /></b-radio>
                  <b-radio value="C"><b-icon-align-middle /></b-radio>
                  <b-radio value="D"><b-icon-align-end /></b-radio>
                </b-form-radio-group>
              </div>

              <div class="col-6 pl-0  text-center">
                <label>Tamanho</label>
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="selected.tamanho"
                  name="radios-tamanho"
                >
                  <b-radio value="G">Grande</b-radio>
                  <b-radio value="M">Médio</b-radio>
                  <b-radio value="N">Normal</b-radio>
                </b-form-radio-group>
              </div>
            </div>
            <hr />
          </div>

          <div
            class=" pr-0 mt-3 text-left"
            :class="selected.tipo == 'T2' ? 'col-6' : 'col-12'"
            v-if="['T', 'T2'].includes(selected.tipo)"
          >
            <b-form-group>
              <b-select
                style="font-size:10px; width:140px"
                :options="tipos_texto"
                v-model="selected.texto_tipo"
              />
              <v-select
                v-if="selected.texto_tipo == 'Variavel' || selected.texto_tipo == 'VariavelDouble'"
                v-model="selected.texto"
                taggable
                multiple
                push-tags
                :options="variaveis.pedido"
                class="mt-1"
              />

              <b-input
                v-if="selected.texto_tipo == 'Livre'"
                v-model="selected.texto"
                max="40"
                class="mt-1"
                :maxlength="
                  selected.tamanho == 'G'
                    ? selected.tipo == 'T2'
                      ? 7
                      : 16
                    : selected.tamanho == 'M'
                    ? selected.tipo == 'T2'
                      ? 11
                      : 24
                    : selected.tipo == 'T2'
                    ? 19
                    : 40
                "
                :style="
                  `margin:0 auto; font-size: ${
                    selected.tamanho == 'G'
                      ? 25
                      : selected.tamanho == 'M'
                      ? 18
                      : 13
                  }px;`
                "
                :class="
                  selected.alinhamento == 'C'
                    ? 'text-center'
                    : selected.alinhamento == 'D'
                    ? 'text-right'
                    : 'text-left'
                "
              />
            </b-form-group>
          </div>
          <div
            class="col-6 pl-0  mt-3 text-left"
            v-if="['T2'].includes(selected.tipo)"
          >
            <b-select
              style="font-size:10px; width:140px"
              :options="tipos_texto"
              v-model="selected.texto2_tipo"
            />
            <v-select
              v-if="selected.texto2_tipo == 'Variavel' || selected.texto2_tipo == 'VariavelDouble'"
              v-model="selected.texto2"
              taggable
              multiple
              push-tags
              :options="variaveis.pedido"
              class="mt-1"
            />
            <b-input
              v-if="selected.texto2_tipo == 'Livre'"
              v-model="selected.texto2"
              max="40"
              class="text-right mt-1"
              :maxlength="
                selected.tamanho == 'G' ? 7 : selected.tamanho == 'M' ? 11 : 19
              "
              :style="
                `margin:0 auto; font-size: ${
                  selected.tamanho == 'G'
                    ? 25
                    : selected.tamanho == 'M'
                    ? 18
                    : 13
                }px;;`
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {},
  mounted() {
    this.modeloPadrao();
  },
  data() {
    return {
      tipos_texto: ["Livre", "Variavel", "Data", "Hora", "VariavelDouble"],
      variaveis: {
        pedido: [
          "{DATA_ATUAL}",
          "{HORA_ATUAL}",
          "{SENHA}",
          "{DATA_PEDIDO}",
          "{COD_PEDIDO}",
          "{VL_SUBTOTAL}",
          "{VL_DESCONTO}",
          "{VL_ENTREGA}",
          "{VL_TOTAL}",
          "{FORMA_PAGAMENTO}",
          "{MOTO}",
          "{CLIENTE_NOME}",
          "{OBSERVACOES}",
          "{USUARIO}",
          "{INICIO_ITEMS}",
          "{ITEM_NUMERO}",
          "{ITEM_PRODUTO}",
          "{ITEM_PRODUTO2}",
          "{ITEM_BORDA}",
          "{ITEM_VALOR}",
          "{ITEM_CANCELADO}",
          "{ITEM_USUARIO}",
          "{FIM_ITEMS}"
        ]
      },
      tipos: [
        { value: "T", text: "Texto" },
        { value: "T2", text: "Texto 2 colunas" },
        { value: "L", text: "Linha" },
        { value: "LP", text: "Linha Pontilhada" }
      ],
      linhas: [],
      selected: null
    };
  },
  watch: {
    selected: {
      deep: true,
      handler(n, o) {
        console.log(n, o);
        if (this.selected.tipo == "T") {
          this.selected.texto = this.selected.texto.substr(
            0,
            this.selected.tamanho == "G"
              ? 16
              : this.selected.tamanho == "M"
              ? 28
              : 40
          );
        }
        if (this.selected.tipo == "D") {
          this.selected.texto = moment().format("DD/MM/YYYY");
        }
        if (this.selected.tipo == "DH") {
          this.selected.texto = moment().format("DD/MM/YYYY");
          this.selected.texto2 = moment().format("HH:mm");
        }
      }
    }
  },
  computed: {},
  methods: {
    modeloPadrao() {
      this.linhas = [];
      this.linhas.push({
        count: 0,
        tipo: "T",
        texto: this.$store.state.empresas.fantasia,
        tamanho: "G",
        negrito: true,
        alinhamento: "C",
        texto_tipo: "Livre"
      });
      this.linhas.push({
        count: 1,
        tipo: "L"
      });
      let qtd = this.linhas.length + 1;
      for (let i = qtd - 1; i < qtd + 10; i++) {
        this.linhas.push({
          count: i
        });
      }
    },
    select(sel) {
      this.selected = sel;
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin selected() {
  background-color: #ffffff30;
}
.prev {
  width: 370px;
  word-break: break-all;
  padding-left: 2px;
  padding-right: 2px;
  border: 15px solid #333;
  min-height: 300px;
  background-color: rgb(253, 225, 102);
  .linha {
    width: 100%;
    min-height: 30px;
    position: relative;
    cursor: pointer;
    &:hover {
      @include selected;
      //   border-bottom: 1px dotted #cccccc30;
    }
    .count {
      position: absolute;
      left: -18px;
      color: white;
      height: 100%;
      font-size: 9px;
      text-align: center;
      width: 20px;
      border-bottom: 1px dotted white;
      padding-bottom: 5px;
      padding-top: 5px;
    }

    .texto {
    }
    .tGrande {
      font-size: 37px;
    }
    .tMedio {
      font-size: 22px;
    }
    .negrito {
      font-weight: bolder;
    }
  }
}
</style>